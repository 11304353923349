[x-cloak] {
    display: none;
}

.fas, .far, .fal, .fad, .fat, .ff-awesome {
    font-family: "Font Awesome 6 Pro", Helvetica, sans-serif;
}

.bg-image {
    background-position: center;
    background-size: cover;

    &.bg-reset-password-image {
        background-image: url(https://source.unsplash.com/OI2Yqj7GDy0/600x800);
    }

    &.bg-inactive-image {
        background-image: url(https://source.unsplash.com/bYXP-ITv4_s/600x800);
    }

    &.bg-activated-image {
        background-image: url(https://source.unsplash.com/xbVTW7sykVw/600x800);
    }

    &.bg-verify-email-image {
        background-image: url(https://source.unsplash.com/AQRp2NH-O8k/600x800);
    }
}

.card {
    .card-header[data-toggle="collapse"] {
        &::after {
            font-family: "Font Awesome 6 Pro", Helvetica, sans-serif;
        }

        &.collapsed {
            border-radius: 0.25rem;
        }
    }

    .card-body {
        padding: 0.5rem 1rem;
    }
}

.progress.progress-sm {
    height: 0.5rem;
}

.custom-switch {
    .custom-control-input:checked ~ .custom-control-label .unchecked-label-part,
    .custom-control-input ~ .custom-control-label .checked-label-part {
        font-weight: normal;
    }

    .custom-control-input ~ .custom-control-label .unchecked-label-part,
    .custom-control-input:checked ~ .custom-control-label .checked-label-part {
        font-weight: bold;
    }

    .custom-control-label.col-form-label-lg {
        &::before {
            width: 2rem;
            height: 1.25rem;
            border-radius: 0.75rem;
            margin-top: 0.5rem;
        }

        &::after {
            width: calc(1.25rem - 4px);
            height: calc(1.25rem - 4px);
            border-radius: 0.75rem;
            margin-top: 0.5rem;
        }
    }
}

.flash-alert {
    display: none;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 2000;
}

.wire-loading {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    position: fixed;
    font-size: 3rem;
    background: rgba(255, 255, 255, 0.5);
    text-align: center;
}

.box {
    padding: 1rem 1rem 0.25rem;
    position: relative;
    background: #e9ecef;

    .remove-box-btn {
        top: 0.75rem;
        right: 1rem;
        z-index: 1;
        position: absolute;
        font-size: 1.25rem;
        font-weight: bold;
    }
}

.w-1 {
    width: 1%;
}

.w-10 {
    width: 10%;
}

#program-selector {
    .search-results {
        top: 75px;
        right: 0;
        left: 0;
        z-index: 4;
    }
}

.border-left-indigo {
    border-left:.25rem solid #6610f2 !important
}

.border-top-0-table tr:first-child {
    td, th {
        border-top: none !important;
    }
}
