// Global
@import "main";

// Data Table
.data-table {
    td, th {
        vertical-align: middle;

        &.fit {
            white-space: nowrap;
            width: 1%;
        }

        .bg-yellow {
            background-color: #FFC107;
        }
    }

    th .btn {
        font-weight: 700;
    }

    .loading {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: inline-block;
        z-index: 5;
        background-color: rgba(255, 255, 255, 0.5);

        &[wire\:loading] {
            display: none;
        }
    }
}

// Sidebar
.sidebar {
    height: 100vh;
    min-width: 300px;
    min-height: auto;
    overflow-y: scroll;
    position: fixed;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .btn {
        box-shadow: none;

        &.collapsed i {
            transform: rotate(-90deg);
        }
    }

    .sidebar-brand .sidebar-brand-text {
        display: inline;
    }

    .sidebar-heading {
        text-align: left;
        margin-bottom: 0.5rem;
    }

    .nav-item {
        .collapse,
        .collapsing {
            margin: 0 0 0 1rem;

            .collapse-inner {
                margin: 0;
            }

            .match .nav-item {
                display: list-item !important;
            }
        }

        .nav-link {
            padding: 6px 1rem;
            width: auto;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &::after {
                content: none !important;
            }

            &.active {
                font-weight: bold;
                color: #fff;
            }

            span {
                font-size: .85rem !important;
                display: inline !important;
            }

            .avatar {
                margin-right: 0.25rem !important;
                font-size: 1.5rem;
                vertical-align: middle;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.toggled {
        .sidebar-brand .sidebar-brand-text {
            display: inline;
        }

        .sidebar-heading {
            text-align: left;
        }

        .nav-item .nav-link {
            padding: 7px 1rem;
            width: auto;
            text-align: left;
        }
    }
}

// Top bar
.topbar {
    .text-dark.bg-hover-dark:hover {
        color: #f8f9fa !important;
    }
}

// Content
#wrapper #content-wrapper {
    width: auto;
    flex-grow: 1;
    min-width: 700px;
    margin-left: 300px;
}

// Edit Program
#edit-program {
    .search-results {
        top: auto;
        bottom: 40px;
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        background-color: #fff;

        .list-group-item {
            background: none;
            cursor: pointer;
            padding: 0.5rem 0.75rem;

            &:first-child {
                border-top: none;
            }

            &.selected {
                color: #004085;
                background: #cce5ff;
            }
        }
    }
}

// User Profile
#user-profile {
    .avatars-group {
        label {
            width: 60px;
        }
    }
}

// ETC
.advice-goodstat {
    color: #28A745FF;
}

.advice-badstat {
    color: #DC3545FF;
}

// Tests Info
#tests-info {
    td {
        vertical-align: middle;
    }

    button[data-toggle="collapse"] {
        &.collapsed .fa-minus,
        &:not(.collapsed) .fa-plus {
            display: none;
        }
    }
}

// Question Answer Distribution
#question-answer-distribution {
    .question-answer-distribution-collapse > i.fa-minus,
    .question-answer-distribution-collapse.collapsed > i.fa-plus{
        display: var(--fa-display,inline-block);
    }

    .question-answer-distribution-collapse > i.fa-plus,
    .question-answer-distribution-collapse.collapsed > i.fa-minus{
        display: none;
    }
}
